/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

//Import styles for toaster
@import "../../node_modules/ngx-toastr/toastr";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

//Import styles for bsDatePicker
@import "../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss";

//Import styles for ng-select
@import "../../node_modules/@ng-select/ng-select/scss/default.theme.scss";

//This is for Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

// Bootstrap
@import "./node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import "~@ng-select/ng-select/themes/default.theme.css";
$text-white: #ffffff;
$text-blue: #0069b4;
$text-light-grey: #8798ad;
$text-grey: #69707f;
$text-black: #2e384d;
$text-green: green;
$text-red: #ff1733;
$text-light: #bfc5d2;
$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;
$primary-blue-color: #922c88;
$border-radius: 0.1rem;
$background-color: #d3e2d6 !important;
$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1d1a1d;
$foreground-color: #242224;
$input-background: #232223;
$foreground-color: #212220;
$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;
$theme-color-1: #7e4877;
$theme-color-2: #3c4b9a;
$theme-color-3: #af67a4;
$theme-color-4: #743c6e;
$theme-color-5: #4b5480;
$theme-color-6: #795d75;
$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;
$gradient-color-1: #8a5381;
$gradient-color-2: #7e4877;
$gradient-color-3: #804a77;
$lp-bg-color-1: #240429;
$lp-bg-color-2: #29072b;
$lp-bg-color-3: #420e40;
$lp-bg-color-4: #52124c;
$border-radius-rounded: 0.75rem;
/* Importing Bootstrap SCSS file. */

.page-item.pagination-next,
.page-item.pagination-prev {
  .page-link {
    margin: 0px 5px;

    &:hover {
      background-color: transparent;
      border-color: #1d267d;
      color: #1d267d;
    }
  }
}

.page-link {
  margin: 0px 5px;
}

.page-item.pagination-last,
.page-item.pagination-first {
  .page-link {
    margin: 0px 5px;

    &:hover {
      background: #1d267d;
      color: #1d267d;
      border: 1px solid #1d267d;
    }
  }
}

// Override default variables
$toast-success-background-color: #28a745;
$toast-success-color: #ffffff;

// Override default CSS classes
.toast-success {
  background-color: $toast-success-background-color;
  color: $toast-success-color;
}

.sidebar {
  background-color: #283448;
}

.theme-green .bs-datepicker-head {
  background-color: #1d267d;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #1d267d;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #1d267d;
}

.page-link.active,
.active > .page-link {
  background-color: #1d267d !important;
}

.pagination {
  margin-top: 20px;
}

.custom-tooltip {
  background-color: #ff0000;
  color: #ffffff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #1d267d;
  color: #fff;
}

.ng-select.ng-select-opened .ng-select-container {
  border-color: #1d267d !important;
  z-index: 3 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  color: #fff;
  background-color: #1d267d;
}

.hideInputNoFileChoosen{
  color: transparent !important;
}

.toast-container{
  position: fixed !important;
  right: 0 !important;
  bottom: 0 !important;

}

datepicker-body table td span.disabled {
  color: #9aaec1;
}

.bs-datepicker-body table td {
  color: #3a3a3a;
}

.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:active, .bs-datepicker-head button[disabled]:hover {
  background: hsla(0,0%,87%,.3);
  color: #f5f5f5;
  cursor: not-allowed;
}

.bs-datepicker-body {
  background-color: transparent!important;
  border: initial;
}